<template>
  <div>
    <div class="navbar">
      <ul class="ul" v-if="navsList">
        <li><span class="text_a" @click="goIndex"> 首页 </span></li>
        <template v-for="(item,index) in navsList" @key="index">
          <li v-if="index<7" :key="index">
            <span class="text_a" @click="golink(item.navigationName,item.id,item.navigationType)"> {{item.navigationName}} </span>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>
<script>
import { getTenant,getNavigationTemplate, } from "@/api/template";
import { logout } from "@/api/user";
import { mapState } from "vuex";
export default {
  name: "TemplateNavbar",
  components: {},
  computed: {
    ...mapState(["color"]),
  },
  data() {
    return {
      activeIndex: 0,
      navsList: [],
      getTenantData: '',
      token:'',
      loginCode:''
    };
  },
  created(){
    this.getGetTenant()
    this.token = window.localStorage.getItem('token')
  },
  methods: {
    init(tid){
      let self = this
      self.navsList = []
      getNavigationTemplate(tid).then(res =>{
        if(res.code == 200){
          self.navsList = res.data
        }else {
          self.$message(res.msg);
        }
      }).catch(() =>{})
    },
    changeActive(e){
      e.currentTarget.className = "active"
    },
    removeActive(e){
      e.currentTarget.className = ""
    },
    changeP(e){
      e.currentTarget.className = "activeP"
    },
    removeP(e){
      e.currentTarget.className = ""
    },
    goIndex(){
      this.$router.push("/template-seventh");
    },
    getGetTenant(){
      getTenant().then(res =>{
        if(res.code == 200){
          this.getTenantData = res.data
          this.init(res.data.tid)
          window.localStorage.setItem("tenant_id",res.data.tenantId)
          window.localStorage.setItem("loginCode",res.data.loginCode)
          this.loginCode = res.data.loginCode
          document.title = res.data.loginTitle
        }else {
          self.$message(res.msg);
        }
      }).catch(() =>{})
    },
    handleSelect(){

    },
    golink(navigationName,id,type) {
      if(this.$route.name == 'seventh-list'){
        this.$router.push({params: {name: navigationName, id: id, type: type, isP: 'true'}, name:'seventh-transfer',})
      }else {
        this.$router.push({params: {name: navigationName, id: id, type: type, isP: 'true'}, name:'seventh-list',})
      }
    },
    login(active){
      if(this.loginCode){
        window.location.href = 'http://' + this.loginCode +'/login?redirect='+active;
      }else {
        window.location.href = process.env.VUE_APP_SAAS_URL +'/login?redirect='+active;
      }
    },
    loginAdmin(){
      if(this.loginCode){
        window.location.href = 'http://' + this.loginCode + '/home'
      }else {
        window.location.href = process.env.VUE_APP_SAAS_URL
      }
    },
    logouts() {
      let _token = window.localStorage.getItem("token");
      logout(_token).then(() => {
        this.token = ''
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("user");
        this.$store.dispatch("saveUser", []);
        this.$store.dispatch("saveHeadActive", "/index");
        this.$store.dispatch("saveSideActive", "/user");
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.navbar{
  width: 1000px;
  height: 45px;
  line-height: 45px;
  //background: #D30212;
  margin: 0 auto 5px;
  border-bottom: 1px solid #c1cdff;
  .ul{
    width: 1000px;
    display: flex;
    flex-flow:row wrap;
    justify-content: start;
    margin: 0 auto;
    color: #000;
    font-weight: bold;
    li{
      flex: 1;
      position: relative;
      height: 45px;
      line-height: 45px;
      //padding: 0 10px;
      text-align: center;
      span{
        color: #000;
        font-size: 16px;
        cursor: pointer;
      }
      .menu{
        width: 120px;
        height: auto;
        position: absolute;
        top: 45px;
        left: 0px;
        //border: 1px #d9d9d9 solid;
        background-color: #fff;
        display: none;
        z-index: 9;
        //box-shadow: #ccc 0px 0px 10px;
        display: none;
        overflow: hidden;
        p{
          text-align: left;
          padding-left: 20px;
          line-height: 30px;
          color: #5c5c5c;
          cursor: pointer;
          span{
            //color: #cc3333;
            display: inline-block;
            cursor: pointer;
            color: #5c5c5c;
            width: 100%;
          }
        }
        .activeP{
          background: #ED7D03;
          span{
            color: #fff;
          }
        }
      }
    }
    .active{
      background: #ED7D03;
      .menu{
        display: block;
        animation: myMou 1s ease-out;
        animation-fill-mode: forwards;
      }
    }
  }
}
@keyframes myMou {
  from { max-height: 0px; }
  to { max-height: 500px; }
}
</style>
